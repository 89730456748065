import './App.css';
import * as React from 'react';
import TTIlogoLight from './images/tti_light.png'; 
import TTIlogoDark from './images/tti_dark.png'; 
import TXDOTlogoLight from './images/txdot_light.png';
import TXDOTlogoDark from './images/txdot_dark.png';
import EmissionsLogo from './images/truck_emissions.png';
import { CssBaseline, Box, Paper, Typography, Grid, Stack, styled, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwitchContent from './SwitchContent';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useEffect, useState, useCallback, useMemo } from 'react';

const DARK_MODE = 'dark';
const LIGHT_MODE = 'light';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5),
    '& > :first-child': {
      fontSize: '1.8rem',
    },
    '& > :nth-child(2)': {
      fontSize: '1rem',
    },
  },
}));

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  minHeight: '100vh',
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(3),
}));

export const handleEmissionsClick = () => {
  const emissionsUrl = 'https://app.powerbi.com/view?r=eyJrIjoiNjI0ZjQyOGMtM2IzYy00OTBjLTkyYzctOGVhMjkzMjZlYWMyIiwidCI6IjVmMThkYjczLTIwZTYtNGEwOC05ZWVjLWUzNjY5MTZiZTg3MSIsImMiOjN9';
  window.open(emissionsUrl, '_blank');
};

export const emptyCache = () => {
        if('caches' in window){
        caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
};

function App() {
  const navigate = useNavigate();
  const handleMainClick = () => navigate('/');
  const handleContactUsClick = () => navigate('/contact-us')
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? DARK_MODE : LIGHT_MODE);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => {
      setMode(event.matches ? DARK_MODE : LIGHT_MODE);
    };
    emptyCache();

    mediaQuery.addEventListener('change', handleChange);
    return () => {
    mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const TTIlogo = mode === 'dark' ? TTIlogoDark : TTIlogoLight;
  const TXDOTlogo = mode === 'dark' ? TXDOTlogoDark : TXDOTlogoLight;
  const toggleTheme = useCallback(() => {
    setMode((prevMode) => (prevMode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer>
        <Stack sx={{ flex: 1 }}>
          <Item sx={{ pt: 1, pl: 2.9, pr: 2.9 }}>
            <Stack justifyContent="space-between" direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
              <div>
                <Button onClick={handleMainClick} variant="h4" size="large" sx={{ fontWeight: 'bold', fontSize: 25 }}>
                  Border Wait Times
                </Button>
              </div>
              <div>
                <div sx={{display: "flex", alignItems: "center" , justifyContent: "center"}}>
                  {!isSmallScreen && 
                    <Button onClick={handleContactUsClick} variant="h6" size="small">
                      Contact us
                    </Button>
                  }
                  <Tooltip title= {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}>
                    <IconButton onClick={toggleTheme} color="inherit">
                      {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Stack>
          </Item>
          <Box sx={{ p: isSmallScreen ? 0.5 : 2, pl: isSmallScreen ? 0.1 : 2.9, pr: isSmallScreen ? 0.1 : 2.9, flexGrow: 1 }}>
            <Item sx={{ borderRadius: '10px' }}>
              <SwitchContent />
            </Item>
          </Box>
          <FooterContainer>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={isSmallScreen ? 'body2' : 'subtitle2'}>
                  This tool provides an interface for the Border Wait Time Measuring System.
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
                  The Border Wait Times is funded by the Texas Department of Transportation. It is developed and maintained by the Texas A&M Transportation Institute.
                </Typography>
              </Grid>
              <Grid item xs={12} mt={1} style={{ display: 'flex', alignItems: 'center', gap:'5px', flexWrap: 'wrap'}}>
                <Button onClick={handleEmissionsClick} color="primary" sx={{ fontSize: 11, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={EmissionsLogo} alt="Emissions" style={{ width: '45px', height: '25px', marginRight: '8px' }} /> 
                  Emissions information
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button onClick={handleContactUsClick} color="primary">
                  <Typography sx={{fontSize: 11, p : "5px" }}> Contact Us </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={1} mt={0.5}>
              <Grid item sm={1.7} md={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: { xs: '200px', md: '300px' }, textAlign: 'center' }}>
                  <img src={TXDOTlogo} alt="TxDOT" style={{ width: '100%' }} />
                </Box>
              </Grid>
              <Grid item sm={1.7} md={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: { xs: '200px', md: '300px' }, textAlign: 'center', marginTop: '10px' }}>
                  <img src={TTIlogo} alt="TTI" style={{ width: '110%' }} />
                </Box>
              </Grid>
            </Grid>
          </FooterContainer>          
        </Stack>
      </AppContainer>
    </ThemeProvider>
  );
}
export default App;
